import { BasketEntryWithDescription, useProcessor } from "../../lib/transaction-generator/contexts/Processor";
import { MouseEvent } from "react";
import Button from "./Button";

interface BasketEntryProps {
  item: BasketEntryWithDescription;
}

const BasketEntry = ({ item }: BasketEntryProps): JSX.Element => {
  const processorCtx = useProcessor();

  const onClickHandler = (event: MouseEvent<HTMLButtonElement>): void => {
    const entryID = (event.target as HTMLButtonElement).value;
    processorCtx.removeItemFromBasket(entryID);
  };

  return (
    <div className="flex flex-row justify-between text-left mb-2 p-4 bg-white rounded shadow-sm content-center">
      <div className="flex flex-col">
        <h4 className="font-bold">
          {item.description} {item.item.tokens?.entryID}
        </h4>
        <p className="text-sm text-gray-400">Quantity: {item.item.quantity}</p>
        <p className="text-sm text-red-400">£{item.item.valueInPence}</p>
      </div>

      <div className="flex flex-col justify-center">
        <Button className="" value={item.item.tokens?.entryID} onClick={onClickHandler}>
          Remove
        </Button>
      </div>
    </div>
  );
};

export default BasketEntry;
