import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Authenticated from "../../components/authenticated";
import { getApiDocumentsClient } from "../../lib/api-helper/apiHelper";
import useApi from "../../lib/hooks/useApi";

export const ApiDocsUnitTest = () => {
  const { project, unitTest } = useParams<{ project: string; unitTest: string }>();
  const [{ data: unitTestHtml, error }, getUnitTestHtml] = useApi(getApiDocumentsClient, "", {
    api: project,
    test: unitTest,
  });

  useEffect(() => {
    getUnitTestHtml("testResultsApiTestGet");
  }, [getUnitTestHtml]);

  return (
    <Authenticated>
      {unitTestHtml ? (
        <iframe
          onLoad={(event) => {
            event.currentTarget.height = `${event.currentTarget.contentWindow.document.body.scrollHeight + 16}px`;
          }}
          title={project}
          srcDoc={unitTestHtml}
          width="100%"
        />
      ) : null}
      {error ? <p>Unable to get unit test results</p> : null}
    </Authenticated>
  );
};
