import { useState, useRef, useEffect } from "react";
import { useV2Devices } from "../useV2Devices";
import useDeviceManagementAPI from "../useDeviceManagementApi";
import { deferredQueue } from "../../helpers";
import { isInit, makeApiVersionedCall, extractResponse } from "./internals";
import { SimulatorDeviceManagementApi } from "../../../openapi/device-management-api";
import useDeviceAPI from "../../hooks/useDeviceApi";
import { DeviceApi } from "../../../openapi/device-api";
import { DeviceSimulator } from "../../../openapi/device-management-api/api";

export const useFetchDevices = () => {
  const [devices, setDevices] = useState<DeviceSimulator[] | undefined>(undefined);
  const v2Devices = useV2Devices();
  const deviceApi = useDeviceAPI();
  const deviceManagementApi = useDeviceManagementAPI();
  const deviceApiRef = useRef<DeviceApi>(deviceApi);
  const deviceManagementApiRef = useRef<SimulatorDeviceManagementApi>(deviceManagementApi);
  const queue = useRef(deferredQueue());

  const getDeviceList = async () => {
    setDevices(
      extractResponse(await makeApiVersionedCall(deviceApiRef.current, deviceManagementApiRef.current, v2Devices))
    );
  };

  const uniqueQueue = () => {
    if (!queue.current.queueLength()) {
      queue.current.enqueue(() => getDeviceList());
    }
  };

  const getDevices = async () => {
    return isInit(deviceApiRef, deviceManagementApiRef) ? getDeviceList() : uniqueQueue();
  };

  useEffect(() => {
    deviceApiRef.current = deviceApi;
    deviceManagementApiRef.current = deviceManagementApi;
    if (isInit(deviceApiRef, deviceManagementApiRef)) {
      queue.current.process();
    }
  }, [deviceApi, deviceManagementApi]);

  return {
    devices,
    getDevices,
  };
};
