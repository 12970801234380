import { Auth } from "aws-amplify";

export const getAccessToken = async (): Promise<string> => {
  const user = await Auth.currentSession();
  return user.getIdToken().getJwtToken();
};

export async function authHeaders(token: string): Promise<{ Authorization: string }> {
  return {
    Authorization: `Bearer ${token}`,
  };
}
