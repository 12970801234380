/* tslint:disable */
/* eslint-disable */
/**
 * Simulator API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.1
 * Contact: tbc@postoffice.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface Device
 */
export interface Device {
    /**
     *
     * @type {string}
     * @memberof Device
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof Device
     */
    'name': string;
    /**
     *
     * @type {string}
     * @memberof Device
     */
    'branch_id': string;
    /**
     *
     * @type {string}
     * @memberof Device
     */
    'created_at': string;
    /**
     *
     * @type {string}
     * @memberof Device
     */
    'type': DeviceTypeEnum;
    /**
     *
     * @type {string}
     * @memberof Device
     */
    'node_id': string;
}

export const DeviceTypeEnum = {
    Counter: 'counter',
    Payzone: 'payzone'
} as const;

export type DeviceTypeEnum = typeof DeviceTypeEnum[keyof typeof DeviceTypeEnum];

/**
 *
 * @export
 * @interface Devices
 */
export interface Devices {
    /**
     *
     * @type {Array<Device>}
     * @memberof Devices
     */
    'devices': Array<Device>;
}
/**
 *
 * @export
 * @interface ItemResponse
 */
export interface ItemResponse {
    /**
     *
     * @type {string}
     * @memberof ItemResponse
     */
    'description'?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof ItemResponse
     */
    'tokens'?: Array<string>;
}
/**
 *
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     *
     * @type {string}
     * @memberof ModelError
     */
    'message': string;
}

/**
 * DeviceApi - axios parameter creator
 * @export
 */
export const DeviceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Get list of user devices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/device`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiGateway required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Unlink a device from a user
         * @param {string} id Device ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceIdLinkDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deviceIdLinkDelete', 'id', id)
            const localVarPath = `/device/{id}/link`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiGateway required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Link a device to user
         * @param {string} id Device ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceIdLinkPost: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deviceIdLinkPost', 'id', id)
            const localVarPath = `/device/{id}/link`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiGateway required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete token for simulator device
         * @param {string} id Device ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceIdTokenDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deviceIdTokenDelete', 'id', id)
            const localVarPath = `/device/{id}/token`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiGateway required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get token for simulator device
         * @param {string} id Device ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceIdTokenGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deviceIdTokenGet', 'id', id)
            const localVarPath = `/device/{id}/token`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiGateway required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete tokens for simulator device key
         * @param {string} id Device ID
         * @param {string} key Token key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceIdTokenKeyDelete: async (id: string, key: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deviceIdTokenKeyDelete', 'id', id)
            // verify required parameter 'key' is not null or undefined
            assertParamExists('deviceIdTokenKeyDelete', 'key', key)
            const localVarPath = `/device/{id}/token/{key}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiGateway required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Create tokens for simulator device
         * @param {string} id Device ID
         * @param {{ [key: string]: string; }} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceIdTokenPut: async (id: string, requestBody: { [key: string]: string; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deviceIdTokenPut', 'id', id)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('deviceIdTokenPut', 'requestBody', requestBody)
            const localVarPath = `/device/{id}/token`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiGateway required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeviceApi - functional programming interface
 * @export
 */
export const DeviceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeviceApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @summary Get list of user devices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Devices>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Unlink a device from a user
         * @param {string} id Device ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceIdLinkDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceIdLinkDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Link a device to user
         * @param {string} id Device ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceIdLinkPost(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceIdLinkPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Delete token for simulator device
         * @param {string} id Device ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceIdTokenDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceIdTokenDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get token for simulator device
         * @param {string} id Device ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceIdTokenGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceIdTokenGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Delete tokens for simulator device key
         * @param {string} id Device ID
         * @param {string} key Token key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceIdTokenKeyDelete(id: string, key: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceIdTokenKeyDelete(id, key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Create tokens for simulator device
         * @param {string} id Device ID
         * @param {{ [key: string]: string; }} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceIdTokenPut(id: string, requestBody: { [key: string]: string; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceIdTokenPut(id, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DeviceApi - factory interface
 * @export
 */
export const DeviceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeviceApiFp(configuration)
    return {
        /**
         *
         * @summary Get list of user devices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceGet(options?: any): AxiosPromise<Devices> {
            return localVarFp.deviceGet(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Unlink a device from a user
         * @param {string} id Device ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceIdLinkDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deviceIdLinkDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Link a device to user
         * @param {string} id Device ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceIdLinkPost(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deviceIdLinkPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Delete token for simulator device
         * @param {string} id Device ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceIdTokenDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deviceIdTokenDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get token for simulator device
         * @param {string} id Device ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceIdTokenGet(id: string, options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.deviceIdTokenGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Delete tokens for simulator device key
         * @param {string} id Device ID
         * @param {string} key Token key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceIdTokenKeyDelete(id: string, key: string, options?: any): AxiosPromise<void> {
            return localVarFp.deviceIdTokenKeyDelete(id, key, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Create tokens for simulator device
         * @param {string} id Device ID
         * @param {{ [key: string]: string; }} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceIdTokenPut(id: string, requestBody: { [key: string]: string; }, options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.deviceIdTokenPut(id, requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeviceApi - object-oriented interface
 * @export
 * @class DeviceApi
 * @extends {BaseAPI}
 */
export class DeviceApi extends BaseAPI {
    /**
     *
     * @summary Get list of user devices
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public deviceGet(options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).deviceGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Unlink a device from a user
     * @param {string} id Device ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public deviceIdLinkDelete(id: string, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).deviceIdLinkDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Link a device to user
     * @param {string} id Device ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public deviceIdLinkPost(id: string, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).deviceIdLinkPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Delete token for simulator device
     * @param {string} id Device ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public deviceIdTokenDelete(id: string, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).deviceIdTokenDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get token for simulator device
     * @param {string} id Device ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public deviceIdTokenGet(id: string, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).deviceIdTokenGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Delete tokens for simulator device key
     * @param {string} id Device ID
     * @param {string} key Token key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public deviceIdTokenKeyDelete(id: string, key: string, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).deviceIdTokenKeyDelete(id, key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Create tokens for simulator device
     * @param {string} id Device ID
     * @param {{ [key: string]: string; }} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public deviceIdTokenPut(id: string, requestBody: { [key: string]: string; }, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).deviceIdTokenPut(id, requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ItemsApi - axios parameter creator
 * @export
 */
export const ItemsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Get Item description and required tokens
         * @param {string} id Item ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('itemIdGet', 'id', id)
            const localVarPath = `/item/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiGateway required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ItemsApi - functional programming interface
 * @export
 */
export const ItemsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ItemsApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @summary Get Item description and required tokens
         * @param {string} id Item ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async itemIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.itemIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ItemsApi - factory interface
 * @export
 */
export const ItemsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ItemsApiFp(configuration)
    return {
        /**
         *
         * @summary Get Item description and required tokens
         * @param {string} id Item ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemIdGet(id: string, options?: any): AxiosPromise<ItemResponse> {
            return localVarFp.itemIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ItemsApi - object-oriented interface
 * @export
 * @class ItemsApi
 * @extends {BaseAPI}
 */
export class ItemsApi extends BaseAPI {
    /**
     *
     * @summary Get Item description and required tokens
     * @param {string} id Item ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public itemIdGet(id: string, options?: AxiosRequestConfig) {
        return ItemsApiFp(this.configuration).itemIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TokensApi - axios parameter creator
 * @export
 */
export const TokensApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Delete token for simulator device
         * @param {string} id Device ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceIdTokenDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deviceIdTokenDelete', 'id', id)
            const localVarPath = `/device/{id}/token`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiGateway required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get token for simulator device
         * @param {string} id Device ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceIdTokenGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deviceIdTokenGet', 'id', id)
            const localVarPath = `/device/{id}/token`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiGateway required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete tokens for simulator device key
         * @param {string} id Device ID
         * @param {string} key Token key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceIdTokenKeyDelete: async (id: string, key: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deviceIdTokenKeyDelete', 'id', id)
            // verify required parameter 'key' is not null or undefined
            assertParamExists('deviceIdTokenKeyDelete', 'key', key)
            const localVarPath = `/device/{id}/token/{key}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiGateway required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Create tokens for simulator device
         * @param {string} id Device ID
         * @param {{ [key: string]: string; }} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceIdTokenPut: async (id: string, requestBody: { [key: string]: string; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deviceIdTokenPut', 'id', id)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('deviceIdTokenPut', 'requestBody', requestBody)
            const localVarPath = `/device/{id}/token`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiGateway required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TokensApi - functional programming interface
 * @export
 */
export const TokensApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TokensApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @summary Delete token for simulator device
         * @param {string} id Device ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceIdTokenDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceIdTokenDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get token for simulator device
         * @param {string} id Device ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceIdTokenGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceIdTokenGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Delete tokens for simulator device key
         * @param {string} id Device ID
         * @param {string} key Token key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceIdTokenKeyDelete(id: string, key: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceIdTokenKeyDelete(id, key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Create tokens for simulator device
         * @param {string} id Device ID
         * @param {{ [key: string]: string; }} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceIdTokenPut(id: string, requestBody: { [key: string]: string; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceIdTokenPut(id, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TokensApi - factory interface
 * @export
 */
export const TokensApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TokensApiFp(configuration)
    return {
        /**
         *
         * @summary Delete token for simulator device
         * @param {string} id Device ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceIdTokenDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deviceIdTokenDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get token for simulator device
         * @param {string} id Device ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceIdTokenGet(id: string, options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.deviceIdTokenGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Delete tokens for simulator device key
         * @param {string} id Device ID
         * @param {string} key Token key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceIdTokenKeyDelete(id: string, key: string, options?: any): AxiosPromise<void> {
            return localVarFp.deviceIdTokenKeyDelete(id, key, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Create tokens for simulator device
         * @param {string} id Device ID
         * @param {{ [key: string]: string; }} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceIdTokenPut(id: string, requestBody: { [key: string]: string; }, options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.deviceIdTokenPut(id, requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TokensApi - object-oriented interface
 * @export
 * @class TokensApi
 * @extends {BaseAPI}
 */
export class TokensApi extends BaseAPI {
    /**
     *
     * @summary Delete token for simulator device
     * @param {string} id Device ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokensApi
     */
    public deviceIdTokenDelete(id: string, options?: AxiosRequestConfig) {
        return TokensApiFp(this.configuration).deviceIdTokenDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get token for simulator device
     * @param {string} id Device ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokensApi
     */
    public deviceIdTokenGet(id: string, options?: AxiosRequestConfig) {
        return TokensApiFp(this.configuration).deviceIdTokenGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Delete tokens for simulator device key
     * @param {string} id Device ID
     * @param {string} key Token key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokensApi
     */
    public deviceIdTokenKeyDelete(id: string, key: string, options?: AxiosRequestConfig) {
        return TokensApiFp(this.configuration).deviceIdTokenKeyDelete(id, key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Create tokens for simulator device
     * @param {string} id Device ID
     * @param {{ [key: string]: string; }} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokensApi
     */
    public deviceIdTokenPut(id: string, requestBody: { [key: string]: string; }, options?: AxiosRequestConfig) {
        return TokensApiFp(this.configuration).deviceIdTokenPut(id, requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}
