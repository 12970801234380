/* tslint:disable */
/* eslint-disable */
/**
 * API Documents
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.1
 * Contact: tbc@postoffice.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ApiSpecs
 */
export interface ApiSpecs {
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiSpecs
     */
    'specs': Array<string>;
}
/**
 * 
 * @export
 * @interface TestSpecs
 */
export interface TestSpecs {
    /**
     * 
     * @type {Array<string>}
     * @memberof TestSpecs
     */
    'specs': Array<string>;
}

/**
 * APIDocumentsApi - axios parameter creator
 * @export
 */
export const APIDocumentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get api spec
         * @param {string} api API Name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiApiGet: async (api: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'api' is not null or undefined
            assertParamExists('openapiApiGet', 'api', api)
            const localVarPath = `/openapi/{api}`
                .replace(`{${"api"}}`, encodeURIComponent(String(api)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary File index
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rootGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get List of unit test results
         * @param {string} api API Name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testResultsApiGet: async (api: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'api' is not null or undefined
            assertParamExists('testResultsApiGet', 'api', api)
            const localVarPath = `/testResults/{api}`
                .replace(`{${"api"}}`, encodeURIComponent(String(api)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get test result
         * @param {string} api API Name
         * @param {string} test Test Name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testResultsApiTestGet: async (api: string, test: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'api' is not null or undefined
            assertParamExists('testResultsApiTestGet', 'api', api)
            // verify required parameter 'test' is not null or undefined
            assertParamExists('testResultsApiTestGet', 'test', test)
            const localVarPath = `/testResults/{api}/{test}`
                .replace(`{${"api"}}`, encodeURIComponent(String(api)))
                .replace(`{${"test"}}`, encodeURIComponent(String(test)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * APIDocumentsApi - functional programming interface
 * @export
 */
export const APIDocumentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = APIDocumentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get api spec
         * @param {string} api API Name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async openapiApiGet(api: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.openapiApiGet(api, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary File index
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rootGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSpecs>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rootGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get List of unit test results
         * @param {string} api API Name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testResultsApiGet(api: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestSpecs>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testResultsApiGet(api, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get test result
         * @param {string} api API Name
         * @param {string} test Test Name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testResultsApiTestGet(api: string, test: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testResultsApiTestGet(api, test, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * APIDocumentsApi - factory interface
 * @export
 */
export const APIDocumentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = APIDocumentsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get api spec
         * @param {string} api API Name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiApiGet(api: string, options?: any): AxiosPromise<string> {
            return localVarFp.openapiApiGet(api, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary File index
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rootGet(options?: any): AxiosPromise<ApiSpecs> {
            return localVarFp.rootGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get List of unit test results
         * @param {string} api API Name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testResultsApiGet(api: string, options?: any): AxiosPromise<TestSpecs> {
            return localVarFp.testResultsApiGet(api, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get test result
         * @param {string} api API Name
         * @param {string} test Test Name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testResultsApiTestGet(api: string, test: string, options?: any): AxiosPromise<string> {
            return localVarFp.testResultsApiTestGet(api, test, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * APIDocumentsApi - interface
 * @export
 * @interface APIDocumentsApi
 */
export interface APIDocumentsApiInterface {
    /**
     * 
     * @summary Get api spec
     * @param {string} api API Name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIDocumentsApiInterface
     */
    openapiApiGet(api: string, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @summary File index
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIDocumentsApiInterface
     */
    rootGet(options?: AxiosRequestConfig): AxiosPromise<ApiSpecs>;

    /**
     * 
     * @summary Get List of unit test results
     * @param {string} api API Name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIDocumentsApiInterface
     */
    testResultsApiGet(api: string, options?: AxiosRequestConfig): AxiosPromise<TestSpecs>;

    /**
     * 
     * @summary Get test result
     * @param {string} api API Name
     * @param {string} test Test Name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIDocumentsApiInterface
     */
    testResultsApiTestGet(api: string, test: string, options?: AxiosRequestConfig): AxiosPromise<string>;

}

/**
 * APIDocumentsApi - object-oriented interface
 * @export
 * @class APIDocumentsApi
 * @extends {BaseAPI}
 */
export class APIDocumentsApi extends BaseAPI implements APIDocumentsApiInterface {
    /**
     * 
     * @summary Get api spec
     * @param {string} api API Name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIDocumentsApi
     */
    public openapiApiGet(api: string, options?: AxiosRequestConfig) {
        return APIDocumentsApiFp(this.configuration).openapiApiGet(api, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary File index
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIDocumentsApi
     */
    public rootGet(options?: AxiosRequestConfig) {
        return APIDocumentsApiFp(this.configuration).rootGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get List of unit test results
     * @param {string} api API Name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIDocumentsApi
     */
    public testResultsApiGet(api: string, options?: AxiosRequestConfig) {
        return APIDocumentsApiFp(this.configuration).testResultsApiGet(api, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get test result
     * @param {string} api API Name
     * @param {string} test Test Name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIDocumentsApi
     */
    public testResultsApiTestGet(api: string, test: string, options?: AxiosRequestConfig) {
        return APIDocumentsApiFp(this.configuration).testResultsApiTestGet(api, test, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} api 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiApiOptions: async (api: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'api' is not null or undefined
            assertParamExists('openapiApiOptions', 'api', api)
            const localVarPath = `/openapi/{api}`
                .replace(`{${"api"}}`, encodeURIComponent(String(api)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rootOptions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} api 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testResultsApiOptions: async (api: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'api' is not null or undefined
            assertParamExists('testResultsApiOptions', 'api', api)
            const localVarPath = `/testResults/{api}`
                .replace(`{${"api"}}`, encodeURIComponent(String(api)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} api 
         * @param {string} test 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testResultsApiTestOptions: async (api: string, test: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'api' is not null or undefined
            assertParamExists('testResultsApiTestOptions', 'api', api)
            // verify required parameter 'test' is not null or undefined
            assertParamExists('testResultsApiTestOptions', 'test', test)
            const localVarPath = `/testResults/{api}/{test}`
                .replace(`{${"api"}}`, encodeURIComponent(String(api)))
                .replace(`{${"test"}}`, encodeURIComponent(String(test)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} api 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async openapiApiOptions(api: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.openapiApiOptions(api, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rootOptions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rootOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} api 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testResultsApiOptions(api: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testResultsApiOptions(api, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} api 
         * @param {string} test 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testResultsApiTestOptions(api: string, test: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testResultsApiTestOptions(api, test, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {string} api 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiApiOptions(api: string, options?: any): AxiosPromise<void> {
            return localVarFp.openapiApiOptions(api, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rootOptions(options?: any): AxiosPromise<void> {
            return localVarFp.rootOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} api 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testResultsApiOptions(api: string, options?: any): AxiosPromise<void> {
            return localVarFp.testResultsApiOptions(api, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} api 
         * @param {string} test 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testResultsApiTestOptions(api: string, test: string, options?: any): AxiosPromise<void> {
            return localVarFp.testResultsApiTestOptions(api, test, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - interface
 * @export
 * @interface DefaultApi
 */
export interface DefaultApiInterface {
    /**
     * 
     * @param {string} api 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    openapiApiOptions(api: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    rootOptions(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} api 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    testResultsApiOptions(api: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} api 
     * @param {string} test 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    testResultsApiTestOptions(api: string, test: string, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI implements DefaultApiInterface {
    /**
     * 
     * @param {string} api 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public openapiApiOptions(api: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).openapiApiOptions(api, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rootOptions(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).rootOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} api 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public testResultsApiOptions(api: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).testResultsApiOptions(api, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} api 
     * @param {string} test 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public testResultsApiTestOptions(api: string, test: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).testResultsApiTestOptions(api, test, options).then((request) => request(this.axios, this.basePath));
    }
}


