import React, { useState } from "react";
import { useProcessor } from "../../lib/transaction-generator/contexts/Processor";
import Button from "./Button";
import { v4 } from "uuid";
import { BasketItemPayload, FallBackModeFlagEnum, RefundFlagEnum } from "postoffice-commit-and-fulfill";

const cashWithdrawal = (entryIdIn: number, value: number): BasketItemPayload => ({
  transactionStartTime: 0,
  itemID: "50211",
  quantity: -1,
  entryID: entryIdIn,
  valueInPence: value,
  receiptLine: "",
  additionalItems: [],
  stockunitIdentifier: "DD",
  methodOfDataCapture: 3,
  refundFlag: RefundFlagEnum.N,
  fallBackModeFlag: FallBackModeFlagEnum.N,
  tokens: {
    entryID: entryIdIn.toString(),
    requestUDID: v4(),
    agentSlaInfo: "000189",
    amountRequested: value.toString(),
    authorisation_code: "xyz",
    bankTransactionID: "422802",
    cardImpounded: " ",
    clientID: "24233",
    counterSlaInfo: "000754",
    currency: "GBP",
    fee: "0",
    financialTransaction: "1",
    horizonTransactionID: "00-179201-2-6914780-1",
    issuerSchemeID: "248",
    merchantNumber: "                ",
    pan: "4658610000006020",
    receiptDate: "20220209",
    recoveryFlag: "0",
    responseCode: "01",
    routingGateway: "24448",
    settlementDate: "20220209",
    transactionResultCode: "01",
    transactionType: "63",
    fulfilmentAction: "balance",
  },
});

const cashTransaction = (entryIdIn: number, value: number): BasketItemPayload => ({
  quantity: 1,
  entryID: entryIdIn,
  valueInPence: value,
  transactionStartTime: Date.now(),
  itemID: "1",
  additionalItems: [],
  receiptLine: `${entryIdIn},1,1`,
  stockunitIdentifier: "B",
  methodOfDataCapture: 1,
  refundFlag: RefundFlagEnum.N,
  fallBackModeFlag: FallBackModeFlagEnum.N,
  tokens: {
    entryID: entryIdIn.toString(),
    requestUDID: v4(),
  },
});

const EntryMaker = () => {
  const processorCtx = useProcessor();
  const [formValue, setFormValue] = useState<number>(0);
  const [transactionType, setTransactionType] = useState<string>("");
  const [entryId, setEntryId] = useState<number>(1);

  const addCashWithdrawal = async () => {
    const withdrawItem = cashWithdrawal(entryId, formValue);
    processorCtx.addItemToBasket("Barclays Cash Withdrawal", withdrawItem);
    setEntryId((i) => i + 1);
  };

  const addCashTransaction = async () => {
    const cashEntryItem = cashTransaction(entryId, formValue);
    processorCtx.addItemToBasket("Cash transaction", cashEntryItem);
    setEntryId((i) => i + 1);
  };

  const handleTransaction = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, fn: () => Promise<void>) => {
    async function run() {
      try {
        await fn();
      } catch (e) {
        console.error(e);
      }
    }

    run();
  };

  const submitHandler: React.MouseEventHandler<HTMLButtonElement> = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();

    switch (transactionType.toLowerCase()) {
      case "cash":
        handleTransaction(event, addCashTransaction);
        break;
      case "withdrawal":
        handleTransaction(event, addCashWithdrawal);
        break;
      default:
        console.error(`Transaction type not supported`);
    }
  };

  return (
    <div>
      <h3 className="mb-4">Create fake transaction</h3>
      <form>
        <label htmlFor="value" className="block text-sm font-medium text-gray-700 mb-2">
          Value
        </label>
        <div>
          <input
            type="number"
            name="enteredNumber"
            value={formValue}
            className="px-2 py-1 rounded-md"
            onChange={(e) => setFormValue(e.target.valueAsNumber)}
          />
        </div>
        <label htmlFor="value" className="block text-sm font-medium text-gray-700 mt-2 mb-2">
          Transaction type
        </label>
        <div>
          <select
            name="transactionType"
            defaultValue={transactionType}
            className="px-2 py-1 rounded-md"
            onChange={(e) => setTransactionType(e.target.value)}
          >
            <option value="" disabled hidden>
              Please select
            </option>
            <option value="Cash">Cash transaction</option>
            <option value="Withdrawal">Cash Withdrawal (Barclays)</option>
          </select>
        </div>
        <div>
          <Button type="submit" onClick={submitHandler} className={"mt-4"}>
            Create transaction
          </Button>
        </div>
      </form>
    </div>
  );
};

export default EntryMaker;
