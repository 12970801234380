// @ts-nocheck

import { FC, Suspense, lazy } from "react";
import { Redirect, Route, HashRouter as Router, Switch } from "react-router-dom";
import { Flip, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Header from "./components/layout/header";
import { GlobalStateProvider } from "./lib/GlobalState";
import { ApiDocsRoutes } from "./pages/ApiDocs";
import DeviceLinks from "./pages/DeviceLinks";
import Login from "./pages/Login";
import PageNotFound from "./pages/PageNotFound";
import ReceiptViewer from "./pages/ReceiptViewer";
import Setup from "./pages/Setup";
import TransactionGenerator from "./pages/TransactionGenerator";
import BoParent from "./pages/BoUtils/BoParent";

const Preload = (importStatement) => {
  const Component = lazy(importStatement);
  Component.preload = importStatement;
  return Component;
};

const PayzoneSimulator = Preload(() => import("./pages/PayzoneSimulator"));
PayzoneSimulator.preload();

const CounterSimulator = Preload(() => import("./pages/CounterSimulator"));
CounterSimulator.preload();

const SandboxSimulator = Preload(() => import("./pages/SandboxSimulator"));
SandboxSimulator.preload();

const MobileSimulator = Preload(() => import("./pages/MobileSimulator"));
MobileSimulator.preload();

const App: FC = () => (
  <div className="min-h-screen">
    <Router>
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        draggable={false}
        limit={2}
        role="alert"
        transition={Flip}
      />
      <GlobalStateProvider>
        <Header />
        <Switch>
          <Redirect from="/" to="/setup" exact={true} />
          <Route path="/setup" component={Setup} />
          <Route path="/login" component={Login} />
          <Route path="/devices" component={DeviceLinks} />
          <Route path="/transaction-generator" component={TransactionGenerator} />
          <Route path="/receipt-viewer" component={ReceiptViewer} />
          <Route path="/api-docs" component={ApiDocsRoutes} />
          <Route path="/bo-utils" component={BoParent} />
          <Suspense fallback={<div>loading...</div>}>
            <Route path="/simulators/payzone" component={PayzoneSimulator} />
            <Route path="/simulators/journeysandbox/master" component={SandboxSimulator} />
            <Route path="/simulators/counterterminal" component={CounterSimulator} />
            <Route path="/simulators/mobile" component={MobileSimulator} />
          </Suspense>
          <Route component={PageNotFound} />
        </Switch>
      </GlobalStateProvider>
    </Router>
  </div>
);

export default App;
