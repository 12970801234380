import { Constants } from "../../constants";
import { useGlobalState } from "../GlobalState";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

export const useRefreshPage = (deviceType) => {
  const { setDeviceConfig, deviceConfig } = useGlobalState();
  const history = useHistory();

  useEffect(() => {
    const incompleteDeviceConfig = Object.values(deviceConfig).every((o) => o === null);
    if (incompleteDeviceConfig) {
      if (localStorage.getItem(Constants.DEVICE_ID)) {
        setDeviceConfig({
          ...deviceConfig,
          deviceId: deviceType,
          terminalId: localStorage.getItem(Constants.DEVICE_ID),
        });
      } else {
        history.push("/setup");
      }
    } else {
      setDeviceConfig({
        ...deviceConfig,
        deviceId: deviceType,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveDeviceName = (deviceName: string) => {
    localStorage.setItem(Constants.DEVICE_ID, deviceName);
  };

  return {
    saveDeviceName,
  };
};
