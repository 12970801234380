import { HTTP_STATUS } from "../../../constants";
import { AxiosResponse } from "axios";
import { DeviceApi } from "../../../openapi/device-api";
import { Devices } from "../../../openapi/device-api/api";
import { SimulatorDeviceManagementApi } from "../../../openapi/device-management-api";
import { DevicesSimulator } from "../../../openapi/device-management-api/api";

export const isInit = (
  deviceApiRef: React.MutableRefObject<DeviceApi>,
  deviceManagementApiRef: React.MutableRefObject<SimulatorDeviceManagementApi>
) => {
  return (
    deviceApiRef.current?.deviceGet !== undefined && deviceManagementApiRef.current?.v2SimulatorGetDevices !== undefined
  );
};

export const makeApiVersionedCall = async (
  deviceApiRef: DeviceApi,
  deviceManagementApiRef: SimulatorDeviceManagementApi,
  v2_devices: boolean
) => {
  const devicesIds = await deviceApiRef.deviceGet();
  if (devicesIds?.data?.devices) {
    return v2_devices
      ? deviceManagementApiRef.v2SimulatorGetDevices(devicesIds.data.devices.map((device) => device.id))
      : devicesIds;
  }
  return devicesIds;
};

export const extractResponse = (response: AxiosResponse<DevicesSimulator | Devices, any>) => {
  const items = (response as AxiosResponse<DevicesSimulator, any>)?.data?.items;
  const devices = (response as AxiosResponse<Devices, any>)?.data?.devices;
  return response?.status === HTTP_STATUS.OK && (items || devices) ? items || devices : [];
};
