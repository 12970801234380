/* tslint:disable */
/* eslint-disable */
/**
 * BBO Test Utils
 * Creates Pouches
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: po.contact@postoffice.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200
     */
    'details': string;
}
/**
 * 
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2001
     */
    'pouchID': string;
}
/**
 * 
 * @export
 * @interface InlineResponse201
 */
export interface InlineResponse201 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse201
     */
    'fileIdentifier': string;
}
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'errorCode'?: string;
}
/**
 * TC details
 * @export
 * @interface TCData
 */
export interface TCData {
    /**
     * 
     * @type {string}
     * @memberof TCData
     */
    'clientReferenceID': string;
    /**
     * 
     * @type {string}
     * @memberof TCData
     */
    'accountReferenceID': string;
    /**
     * 
     * @type {string}
     * @memberof TCData
     */
    'itemID': string;
    /**
     * 
     * @type {string}
     * @memberof TCData
     */
    'comment': string;
    /**
     * 
     * @type {string}
     * @memberof TCData
     */
    'value': string;
}

/**
 * CreateAPouchForAcceptanceApi - axios parameter creator
 * @export
 */
export const CreateAPouchForAcceptanceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates a pouch
         * @param {string} fadcode Fadcode representing the branchID
         * @param {string} value Value in Pounds of the Pouch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPouch: async (fadcode: string, value: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fadcode' is not null or undefined
            assertParamExists('createPouch', 'fadcode', fadcode)
            // verify required parameter 'value' is not null or undefined
            assertParamExists('createPouch', 'value', value)
            const localVarPath = `/v1/create-pouch/{value}`
                .replace(`{${"value"}}`, encodeURIComponent(String(value)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fadcode !== undefined && fadcode !== null) {
                localVarHeaderParameter['fadcode'] = String(fadcode);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CreateAPouchForAcceptanceApi - functional programming interface
 * @export
 */
export const CreateAPouchForAcceptanceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CreateAPouchForAcceptanceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates a pouch
         * @param {string} fadcode Fadcode representing the branchID
         * @param {string} value Value in Pounds of the Pouch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPouch(fadcode: string, value: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPouch(fadcode, value, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CreateAPouchForAcceptanceApi - factory interface
 * @export
 */
export const CreateAPouchForAcceptanceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CreateAPouchForAcceptanceApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates a pouch
         * @param {string} fadcode Fadcode representing the branchID
         * @param {string} value Value in Pounds of the Pouch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPouch(fadcode: string, value: string, options?: any): AxiosPromise<InlineResponse2001> {
            return localVarFp.createPouch(fadcode, value, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CreateAPouchForAcceptanceApi - interface
 * @export
 * @interface CreateAPouchForAcceptanceApi
 */
export interface CreateAPouchForAcceptanceApiInterface {
    /**
     * 
     * @summary Creates a pouch
     * @param {string} fadcode Fadcode representing the branchID
     * @param {string} value Value in Pounds of the Pouch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreateAPouchForAcceptanceApiInterface
     */
    createPouch(fadcode: string, value: string, options?: AxiosRequestConfig): AxiosPromise<InlineResponse2001>;

}

/**
 * CreateAPouchForAcceptanceApi - object-oriented interface
 * @export
 * @class CreateAPouchForAcceptanceApi
 * @extends {BaseAPI}
 */
export class CreateAPouchForAcceptanceApi extends BaseAPI implements CreateAPouchForAcceptanceApiInterface {
    /**
     * 
     * @summary Creates a pouch
     * @param {string} fadcode Fadcode representing the branchID
     * @param {string} value Value in Pounds of the Pouch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreateAPouchForAcceptanceApi
     */
    public createPouch(fadcode: string, value: string, options?: AxiosRequestConfig) {
        return CreateAPouchForAcceptanceApiFp(this.configuration).createPouch(fadcode, value, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DeletesTasksApi - axios parameter creator
 * @export
 */
export const DeletesTasksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Deletes Tasks
         * @param {string} fadcode Fadcode representing the branchID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTask: async (fadcode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fadcode' is not null or undefined
            assertParamExists('deleteTask', 'fadcode', fadcode)
            const localVarPath = `/v1/deleteTasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fadcode !== undefined && fadcode !== null) {
                localVarHeaderParameter['fadcode'] = String(fadcode);
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeletesTasksApi - functional programming interface
 * @export
 */
export const DeletesTasksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeletesTasksApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @summary Deletes Tasks
         * @param {string} fadcode Fadcode representing the branchID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTask(fadcode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTask(fadcode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DeletesTasksApi - factory interface
 * @export
 */
export const DeletesTasksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeletesTasksApiFp(configuration)
    return {
        /**
         *
         * @summary Deletes Tasks
         * @param {string} fadcode Fadcode representing the branchID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTask(fadcode: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTask(fadcode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeletesTasksApi - interface
 * @export
 * @interface DeletesTasksApi
 */
export interface DeletesTasksApiInterface {
    /**
     *
     * @summary Deletes Tasks
     * @param {string} fadcode Fadcode representing the branchID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeletesTasksApiInterface
     */
    deleteTask(fadcode: string, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * DeletesTasksApi - object-oriented interface
 * @export
 * @class DeletesTasksApi
 * @extends {BaseAPI}
 */
export class DeletesTasksApi extends BaseAPI implements DeletesTasksApiInterface {
    /**
     *
     * @summary Deletes Tasks
     * @param {string} fadcode Fadcode representing the branchID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeletesTasksApi
     */
    public deleteTask(fadcode: string, options?: AxiosRequestConfig) {
        return DeletesTasksApiFp(this.configuration).deleteTask(fadcode, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GenerateBarcodeApi - axios parameter creator
 * @export
 */
export const GenerateBarcodeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Generates a barcode for despatch
         * @param {string} fadcode Fadcode representing the branchID
         * @param {'Cash' | 'Stock' | 'Forex'} pouchType 
         * @param {'Notes' | 'BRP' | 'Coin' | ''} pouchSubType 
         * @param {'PouchDespatch' | 'PouchAccept' | 'Transfer'} operation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateBarcode: async (fadcode: string, pouchType: 'Cash' | 'Stock' | 'Forex', pouchSubType: 'Notes' | 'BRP' | 'Coin' | '', operation: 'PouchDespatch' | 'PouchAccept' | 'Transfer', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fadcode' is not null or undefined
            assertParamExists('generateBarcode', 'fadcode', fadcode)
            // verify required parameter 'pouchType' is not null or undefined
            assertParamExists('generateBarcode', 'pouchType', pouchType)
            // verify required parameter 'pouchSubType' is not null or undefined
            assertParamExists('generateBarcode', 'pouchSubType', pouchSubType)
            // verify required parameter 'operation' is not null or undefined
            assertParamExists('generateBarcode', 'operation', operation)
            const localVarPath = `/v1/generate-barcode/{pouchType}/{pouchSubType}/{operation}`
                .replace(`{${"pouchType"}}`, encodeURIComponent(String(pouchType)))
                .replace(`{${"pouchSubType"}}`, encodeURIComponent(String(pouchSubType)))
                .replace(`{${"operation"}}`, encodeURIComponent(String(operation)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fadcode !== undefined && fadcode !== null) {
                localVarHeaderParameter['fadcode'] = String(fadcode);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GenerateBarcodeApi - functional programming interface
 * @export
 */
export const GenerateBarcodeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GenerateBarcodeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Generates a barcode for despatch
         * @param {string} fadcode Fadcode representing the branchID
         * @param {'Cash' | 'Stock' | 'Forex'} pouchType 
         * @param {'Notes' | 'BRP' | 'Coin' | ''} pouchSubType 
         * @param {'PouchDespatch' | 'PouchAccept' | 'Transfer'} operation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateBarcode(fadcode: string, pouchType: 'Cash' | 'Stock' | 'Forex', pouchSubType: 'Notes' | 'BRP' | 'Coin' | '', operation: 'PouchDespatch' | 'PouchAccept' | 'Transfer', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateBarcode(fadcode, pouchType, pouchSubType, operation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GenerateBarcodeApi - factory interface
 * @export
 */
export const GenerateBarcodeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GenerateBarcodeApiFp(configuration)
    return {
        /**
         * 
         * @summary Generates a barcode for despatch
         * @param {string} fadcode Fadcode representing the branchID
         * @param {'Cash' | 'Stock' | 'Forex'} pouchType 
         * @param {'Notes' | 'BRP' | 'Coin' | ''} pouchSubType 
         * @param {'PouchDespatch' | 'PouchAccept' | 'Transfer'} operation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateBarcode(fadcode: string, pouchType: 'Cash' | 'Stock' | 'Forex', pouchSubType: 'Notes' | 'BRP' | 'Coin' | '', operation: 'PouchDespatch' | 'PouchAccept' | 'Transfer', options?: any): AxiosPromise<InlineResponse2001> {
            return localVarFp.generateBarcode(fadcode, pouchType, pouchSubType, operation, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GenerateBarcodeApi - interface
 * @export
 * @interface GenerateBarcodeApi
 */
export interface GenerateBarcodeApiInterface {
    /**
     * 
     * @summary Generates a barcode for despatch
     * @param {string} fadcode Fadcode representing the branchID
     * @param {'Cash' | 'Stock' | 'Forex'} pouchType 
     * @param {'Notes' | 'BRP' | 'Coin' | ''} pouchSubType 
     * @param {'PouchDespatch' | 'PouchAccept' | 'Transfer'} operation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GenerateBarcodeApiInterface
     */
    generateBarcode(fadcode: string, pouchType: 'Cash' | 'Stock' | 'Forex', pouchSubType: 'Notes' | 'BRP' | 'Coin' | '', operation: 'PouchDespatch' | 'PouchAccept' | 'Transfer', options?: AxiosRequestConfig): AxiosPromise<InlineResponse2001>;

}

/**
 * GenerateBarcodeApi - object-oriented interface
 * @export
 * @class GenerateBarcodeApi
 * @extends {BaseAPI}
 */
export class GenerateBarcodeApi extends BaseAPI implements GenerateBarcodeApiInterface {
    /**
     * 
     * @summary Generates a barcode for despatch
     * @param {string} fadcode Fadcode representing the branchID
     * @param {'Cash' | 'Stock' | 'Forex'} pouchType 
     * @param {'Notes' | 'BRP' | 'Coin' | ''} pouchSubType 
     * @param {'PouchDespatch' | 'PouchAccept' | 'Transfer'} operation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GenerateBarcodeApi
     */
    public generateBarcode(fadcode: string, pouchType: 'Cash' | 'Stock' | 'Forex', pouchSubType: 'Notes' | 'BRP' | 'Coin' | '', operation: 'PouchDespatch' | 'PouchAccept' | 'Transfer', options?: AxiosRequestConfig) {
        return GenerateBarcodeApiFp(this.configuration).generateBarcode(fadcode, pouchType, pouchSubType, operation, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GenerateTCApi - axios parameter creator
 * @export
 */
export const GenerateTCApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates a TC
         * @param {string} fadcode Fadcode representing the branchID
         * @param {TCData} [tCData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTc: async (fadcode: string, tCData?: TCData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fadcode' is not null or undefined
            assertParamExists('generateTc', 'fadcode', fadcode)
            const localVarPath = `/v1/generateTC`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fadcode !== undefined && fadcode !== null) {
                localVarHeaderParameter['fadcode'] = String(fadcode);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tCData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Resets Balances
         * @param {string} fadcode Fadcode representing the branchID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetBalance: async (fadcode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fadcode' is not null or undefined
            assertParamExists('resetBalance', 'fadcode', fadcode)
            const localVarPath = `/v1/resetBalance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fadcode !== undefined && fadcode !== null) {
                localVarHeaderParameter['fadcode'] = String(fadcode);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GenerateTCApi - functional programming interface
 * @export
 */
export const GenerateTCApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GenerateTCApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates a TC
         * @param {string} fadcode Fadcode representing the branchID
         * @param {TCData} [tCData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateTc(fadcode: string, tCData?: TCData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse201>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateTc(fadcode, tCData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Resets Balances
         * @param {string} fadcode Fadcode representing the branchID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetBalance(fadcode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetBalance(fadcode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GenerateTCApi - factory interface
 * @export
 */
export const GenerateTCApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GenerateTCApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates a TC
         * @param {string} fadcode Fadcode representing the branchID
         * @param {TCData} [tCData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTc(fadcode: string, tCData?: TCData, options?: any): AxiosPromise<InlineResponse201> {
            return localVarFp.generateTc(fadcode, tCData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Resets Balances
         * @param {string} fadcode Fadcode representing the branchID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetBalance(fadcode: string, options?: any): AxiosPromise<void> {
            return localVarFp.resetBalance(fadcode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GenerateTCApi - interface
 * @export
 * @interface GenerateTCApi
 */
export interface GenerateTCApiInterface {
    /**
     * 
     * @summary Creates a TC
     * @param {string} fadcode Fadcode representing the branchID
     * @param {TCData} [tCData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GenerateTCApiInterface
     */
    generateTc(fadcode: string, tCData?: TCData, options?: AxiosRequestConfig): AxiosPromise<InlineResponse201>;

    /**
     * 
     * @summary Resets Balances
     * @param {string} fadcode Fadcode representing the branchID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GenerateTCApiInterface
     */
    resetBalance(fadcode: string, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * GenerateTCApi - object-oriented interface
 * @export
 * @class GenerateTCApi
 * @extends {BaseAPI}
 */
export class GenerateTCApi extends BaseAPI implements GenerateTCApiInterface {
    /**
     * 
     * @summary Creates a TC
     * @param {string} fadcode Fadcode representing the branchID
     * @param {TCData} [tCData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GenerateTCApi
     */
    public generateTc(fadcode: string, tCData?: TCData, options?: AxiosRequestConfig) {
        return GenerateTCApiFp(this.configuration).generateTc(fadcode, tCData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Resets Balances
     * @param {string} fadcode Fadcode representing the branchID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GenerateTCApi
     */
    public resetBalance(fadcode: string, options?: AxiosRequestConfig) {
        return GenerateTCApiFp(this.configuration).resetBalance(fadcode, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GetFadDetailsApi - axios parameter creator
 * @export
 */
export const GetFadDetailsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Fad Details
         * @param {string} fadcode Fadcode representing the branchID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fadDetails: async (fadcode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fadcode' is not null or undefined
            assertParamExists('fadDetails', 'fadcode', fadcode)
            const localVarPath = `/v1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fadcode !== undefined && fadcode !== null) {
                localVarHeaderParameter['fadcode'] = String(fadcode);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GetFadDetailsApi - functional programming interface
 * @export
 */
export const GetFadDetailsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GetFadDetailsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Fad Details
         * @param {string} fadcode Fadcode representing the branchID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fadDetails(fadcode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fadDetails(fadcode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GetFadDetailsApi - factory interface
 * @export
 */
export const GetFadDetailsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GetFadDetailsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Fad Details
         * @param {string} fadcode Fadcode representing the branchID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fadDetails(fadcode: string, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.fadDetails(fadcode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GetFadDetailsApi - interface
 * @export
 * @interface GetFadDetailsApi
 */
export interface GetFadDetailsApiInterface {
    /**
     * 
     * @summary Get Fad Details
     * @param {string} fadcode Fadcode representing the branchID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetFadDetailsApiInterface
     */
    fadDetails(fadcode: string, options?: AxiosRequestConfig): AxiosPromise<InlineResponse200>;

}

/**
 * GetFadDetailsApi - object-oriented interface
 * @export
 * @class GetFadDetailsApi
 * @extends {BaseAPI}
 */
export class GetFadDetailsApi extends BaseAPI implements GetFadDetailsApiInterface {
    /**
     * 
     * @summary Get Fad Details
     * @param {string} fadcode Fadcode representing the branchID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetFadDetailsApi
     */
    public fadDetails(fadcode: string, options?: AxiosRequestConfig) {
        return GetFadDetailsApiFp(this.configuration).fadDetails(fadcode, options).then((request) => request(this.axios, this.basePath));
    }
}


