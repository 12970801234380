import React, { FC, useEffect, useState, PropsWithChildren } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { awsSession } from "../../lib/AWS";
import { useLDClient } from "launchdarkly-react-client-sdk";

const Authenticated: FC<PropsWithChildren> = ({ children }) => {
  const [authStatus, setAuthStatus] = useState<"authenticated" | "authenticating" | "not authenticated">(
    "authenticating"
  );

  const history = useHistory();
  const location = useLocation();
  const ldClient = useLDClient();

  useEffect(() => {
    const checkSession = async () => {
      try {
        const session = await awsSession();
        setAuthStatus("authenticated");
        ldClient.identify(
          {
            kind: "user",
            key: session.username,
            email: session.email,
          },
          null,
          () => {
            console.debug("New context's flags available");
          }
        );
      } catch (err) {
        setAuthStatus("not authenticated");
      }
    };

    checkSession();
  });
  if (authStatus === "authenticated") {
    return children as React.ReactElement;
  } else if (authStatus === "not authenticated") {
    history.replace(`/login?return=${escape(location.pathname)}`);
  }

  return null;
};

export default Authenticated;
