export type Task = () => void;

export const deferredQueue = () => {
  const queue: Task[] = [];
  let isProcessing = false;

  const enqueue = (task: Task) => {
    queue.push(task);
  };

  const process = async () => {
    if (isProcessing) {
      return;
    }
    isProcessing = true;
    while (queue.length > 0) {
      const next = queue.shift();
      if (typeof next === "function") {
        await next();
      }
    }
    isProcessing = false;
  };

  const queueLength = () => queue.length;
  return {
    queueLength,
    enqueue,
    process,
  };
};
