import {
  getFadDetailsApiClient,
  getGenerateTCApiClient,
  getGeneratePouchApiClient,
  getGenerateBarcodeApiClient,
} from "../../../lib/api-helper/apiHelper";
import { initialTcFormData } from "./utils";

// API Responses
export const fetchDetailsByFadcode = async (fadcode: string) => {
  const response = await getFadDetailsApiClient(fadcode);
  return response.details;
};

export const generatePouch = async (fadCode: string, numOfNotes: string) => {
  const value = parseInt(numOfNotes, 10) * 20;
  const api = await getGeneratePouchApiClient();
  const response = await api.createPouch(fadCode, value.toString());
  return response.data.pouchID;
};

export const generateDespatchBarcode = async (
  fadcode: string,
  pouchType: "Cash" | "Stock" | "Forex",
  pouchSubType: "Notes" | "BRP" | "Coin" | "",
  operation: "PouchDespatch" | "PouchAccept" | "Transfer"
) => {
  try {
    const api = await getGenerateBarcodeApiClient();
    const response = await api.generateBarcode(fadcode, pouchType, pouchSubType, operation);
    return response.data;
  } catch (error) {
    console.error("Error generating despatch barcode:", error);
    throw error;
  }
};

export const generateTc = async (
  fadCode: string,
  formData: {
    clientReferenceID: string;
    accountReferenceID: string;
    itemID: string;
    comment: string;
    value: string;
  }
) => {
  const api = await getGenerateTCApiClient();
  const response = await api.generateTc(fadCode, formData);
  return response.data;
};

export const resetBalance = async (fadCode: string) => {
  const api = await getGenerateTCApiClient();
  const response = await api.resetBalance(fadCode);
  return response.data;
};

// API Handling
export const getFadData = async (fadCode: string) => {
  try {
    const data = await fetchDetailsByFadcode(fadCode);
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const generatePouchId = async (fadCode: string, numOfNotes: number) => {
  try {
    const value = numOfNotes * 20;
    const pouchID = await generatePouch(fadCode, value.toString());
    return pouchID;
  } catch (error) {
    console.error("Error generating pouch:", error);
    throw error;
  }
};

export const generateTcResult = async (fadCode: string, formData: typeof initialTcFormData) => {
  try {
    const result = await generateTc(fadCode, formData);
    return JSON.stringify(result);
  } catch (error) {
    console.error("Error generating TC:", error);
    throw error;
  }
};
