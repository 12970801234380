import { Route, Switch } from "react-router-dom";
import { ApiDocsProject } from "./ApiDocsProject";
import { ApiDocsProjects } from "./ApiDocsProjects";
import { ApiDocsUnitTest } from "./ApiDocsUnitTest";
import { ApiDocsUnitTests } from "./ApiDocsUnitTests";

export const ApiDocsRoutes = () => {
  return (
    <Switch>
      <Route exact path="/api-docs" component={ApiDocsProjects} />
      <Route exact path="/api-docs/project/:project" component={ApiDocsProject} />
      <Route exact path="/api-docs/project/:project/unitTests" component={ApiDocsUnitTests} />
      <Route exact path="/api-docs/project/:project/unitTests/:unitTest" component={ApiDocsUnitTest} />
    </Switch>
  );
};
