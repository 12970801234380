import { useHistory, useLocation } from "react-router-dom";

interface TabProps {
  route: string;
  label: string;
}

export const Tab = ({ route, label }: TabProps) => {
  const history = useHistory();
  const location = useLocation();

  return (
    <div className="cursor-pointer sm:-my-px sm:ml-6 sm:flex sm:space-x-8" onClick={() => history.push(route)}>
      <h3
        className={`inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900 border-b-2  ${
          location.pathname === route ? "border-pol-red border-b-2" : "border-gray-200 border-b"
        }`}
      >
        {label}
      </h3>
    </div>
  );
};
