import { ChangeEvent, useState } from "react";
import { isDataPopulated, initialTcFormData, formattedDate } from "./utils/utils";
import {
  getFadData,
  generatePouchId,
  generateTcResult,
  generateDespatchBarcode,
  resetBalance,
} from "./utils/boApiHelper";

type PouchType = "Cash" | "Stock" | "Forex";
type PouchSubType = "" | "Notes" | "BRP" | "Coin";
type Operation = "PouchDespatch" | "PouchAccept" | "Transfer";

interface InputSectionProps {
  fadCode: string;
  setFadCode: React.Dispatch<React.SetStateAction<string>>;
  isValidFadcode: boolean | null;
  setIsValidFadcode: React.Dispatch<React.SetStateAction<boolean | null>>;
  isDataFetched: boolean;
  numOfNotes: string;
  setNumOfNotes: React.Dispatch<React.SetStateAction<string>>;
  setFadcodeData: React.Dispatch<React.SetStateAction<string>>;
  fadcodeData: string;
  setDataIsFetched: React.Dispatch<React.SetStateAction<boolean>>;
  setPouchBarcode: React.Dispatch<React.SetStateAction<string | null>>;
  setDespatchBarcode: React.Dispatch<React.SetStateAction<string | null>>;
  setErrorMessage?: React.Dispatch<React.SetStateAction<string | null>>;
  setResetBalanceResult?: React.Dispatch<React.SetStateAction<string | null>>;
  setTcResult: React.Dispatch<React.SetStateAction<string | null>>;
  lastSubmittedData: typeof initialTcFormData;
  setLastSubmittedData: React.Dispatch<React.SetStateAction<typeof initialTcFormData>>;
  initialTcFormData: typeof initialTcFormData;
}
interface InputFieldProps {
  label: string;
  id: string;
  value?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  maxLength?: number;
}

const inputFieldConfig = {
  clientReferenceID: { colSpan: 1, additionalClasses: "" },
  itemID: { colSpan: 1, additionalClasses: "" },
  value: { colSpan: 1, additionalClasses: "" },
  accountReferenceID: { colSpan: 3, additionalClasses: "mt-1" },
  comment: { colSpan: 3, additionalClasses: "mt-1" },
};

const InputField = ({ label, id, value, onChange, className = "w-1/4", maxLength }: InputFieldProps) => (
  <div className="mb-5">
    <label htmlFor={id} className="block mb-2">
      {label}:
    </label>
    <input
      type="text"
      id={id}
      value={value}
      onChange={onChange}
      className={`p-2 border rounded ${className}`}
      maxLength={maxLength}
    />
  </div>
);

const InputSection = ({
  fadCode,
  setFadCode,
  isValidFadcode,
  setIsValidFadcode,
  isDataFetched,
  numOfNotes,
  setNumOfNotes,
  setFadcodeData,
  fadcodeData,
  setDataIsFetched,
  setPouchBarcode,
  setDespatchBarcode,
  setErrorMessage,
  setResetBalanceResult,
  setTcResult,
  initialTcFormData,
  setLastSubmittedData,
}: InputSectionProps) => {
  const [formData, setFormData] = useState(initialTcFormData);

  const [pouchType, setPouchType] = useState<PouchType>("Cash");
  const [pouchSubType, setPouchSubType] = useState<PouchSubType>("");
  const [operation, setOperation] = useState<Operation>("PouchDespatch");

  const resetFormData = () => {
    setFormData(initialTcFormData);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [id]: value }));
  };

  const fetchFadData = async () => {
    try {
      const details = await getFadData(fadCode);
      const isPopulated = isDataPopulated(details);
      setIsValidFadcode(isPopulated);
      setFadcodeData(details);
      setDataIsFetched(true);
      setPouchBarcode(null);
      setDespatchBarcode(null);
      setTcResult(null);
      resetFormData();
    } catch (error) {
      console.error("Error fetching data:", error);
      setFadcodeData(`Error: ${error.message || "An unexpected error occurred."}`);
      setDataIsFetched(false);
    }
  };

  const handleGeneratePouchCall = async () => {
    try {
      const pouchID = await generatePouchId(fadCode, parseInt(numOfNotes, 10));
      setPouchBarcode(pouchID);
    } catch (error) {
      console.error("Error generating pouch:", error);
    }
  };

  const handleGenerateTcCall = async () => {
    try {
      const result = await generateTcResult(fadCode, formData);
      setTcResult(result);
      setLastSubmittedData(formData);
    } catch (error) {
      console.error("Error generating TC:", error);
    }
  };

  const handleGenerateDespatchBarcode = async () => {
    try {
      if (pouchType && pouchSubType && operation) {
        const response = await generateDespatchBarcode(fadCode, pouchType, pouchSubType, operation);
        setDespatchBarcode(response.pouchID);
      } else {
        console.error("Invalid pouchType or pouchSubType:", pouchType, pouchSubType);
        setErrorMessage("Invalid selection");
      }
    } catch (error) {
      console.error("Error generating despatch barcode:", error);
      setErrorMessage(error.message);
    }
  };

  const downloadCsv = () => {
    const fileName = `${fadCode}-${formattedDate()}.csv`;
    const blob = new Blob([fadcodeData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleResetBalance = async () => {
    try {
      await resetBalance(fadCode);
      setResetBalanceResult("Balance successfully reset");
    } catch (error) {
      console.error("Error resetting balance:", error);
      setResetBalanceResult(error.message);
    }
  };

  function getButtonClass(isDisabled: boolean, fullWidth: boolean = true) {
    const widthClass = fullWidth ? "w-full" : "flex-1";
    return `p-2 rounded ${widthClass} ${
      isDisabled ? "bg-gray-300 text-gray-500 cursor-not-allowed" : "bg-blue-500 text-white"
    }`;
  }

  return (
    <div className="flex flex-col space-y-8">
      <h2 className="mb-1 text-lg font-bold">Fadcode Data</h2>
      {/* Fadcode Form */}
      <div className="flex flex-col space-y-4 mb-4">
        <InputField
          label="Fadcode"
          id="fadcode"
          value={fadCode}
          maxLength={7}
          onChange={(e) => setFadCode(e.target.value)}
          className="w-1/2"
        />
        <div className="flex space-x-4 w-full">
          <button
            onClick={() => {
              fetchFadData();
              resetFormData();
            }}
            disabled={fadCode.length !== 7}
            className={getButtonClass(fadCode.length !== 7)}
          >
            Generate Fadcode Data
          </button>
        </div>
      </div>
      {/* Pouch and Pouch Despatch Form */}
      <div className="flex flex-col space-y-6">
        <h2 className="mb-3 text-lg font-bold">Pouch Barcode Generation</h2>
        <div className="w-full mb-4">
          <InputField
            label="No. of 20 Notes"
            id="notes"
            value={numOfNotes}
            onChange={(e) => setNumOfNotes(e.target.value)}
            className="w-1/2"
          />
        </div>

        <button
          onClick={handleGeneratePouchCall}
          disabled={!isDataFetched || !isValidFadcode}
          className={getButtonClass(!isDataFetched || !isValidFadcode)}
        >
          Generate Pouch Barcode
        </button>

        <h2 className="mb-3 text-lg font-bold mt-6">Pouch Despatch Barcode Generation</h2>
        <div className="flex space-x-4 w-full">
          <select
            value={pouchType}
            onChange={(e) => setPouchType(e.target.value as PouchType)}
            className="p-2 border rounded w-1/3"
          >
            <option value="" disabled>
              Pouch Type
            </option>
            <option value="Cash">Cash</option>
            <option value="Stock">Stock</option>
            <option value="Forex">Forex</option>
          </select>

          <select
            value={pouchSubType}
            onChange={(e) => setPouchSubType(e.target.value as PouchSubType)}
            className="p-2 border rounded w-1/3"
          >
            <option value="" disabled>
              Sub Type
            </option>
            <option value="Notes">Notes</option>
            <option value="BRP">BRP</option>
            <option value="Coin">Coin</option>
          </select>

          <select
            value={operation}
            onChange={(e) => setOperation(e.target.value as Operation)}
            className="p-2 border rounded w-1/3"
          >
            <option value="" disabled>
              Operation
            </option>
            <option value="PouchDespatch">Pouch Despatch</option>
            <option value="PouchAccept">Pouch Accept</option>
            <option value="Transfer">Transfer</option>
          </select>
        </div>

        <button
          onClick={() => {
            setErrorMessage(null);
            handleGenerateDespatchBarcode();
          }}
          disabled={!isDataFetched || !isValidFadcode || !pouchType || !operation}
          /*
          Check if pouchSubType is optional / not always needed - not sure if all 3 are needed every time
          At moment of testing, response back is 400 if pouchSubType is not provided
          */
          className={getButtonClass(!isDataFetched || !isValidFadcode || !pouchType || !operation)}
        >
          Generate Pouch Despatch Barcode
        </button>
      </div>

      {/* Mapped Forms */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-2 mt-6">
        <h2 className="col-span-3 mb-3 text-lg font-bold">TC Generation</h2>

        {Object.keys(inputFieldConfig).map((id) => (
          <div
            key={id}
            className={`col-span-${inputFieldConfig[id].colSpan} md:col-span-${inputFieldConfig[id].colSpan} ${inputFieldConfig[id].additionalClasses}`}
          >
            <InputField label={id} id={id} value={formData[id]} onChange={handleInputChange} className="w-full" />
          </div>
        ))}

        <button
          className={`col-span-3 ${getButtonClass(!isDataFetched || !isValidFadcode)}`}
          onClick={handleGenerateTcCall}
          disabled={!isDataFetched || !isValidFadcode}
        >
          Generate TC
        </button>
      </div>
      <div className="mt-4">
        <h2 className="mb-3 text-lg font-bold">Misc</h2>
        <div className="flex space-x-4">
          <button
            onClick={downloadCsv}
            disabled={!isDataFetched || !isValidFadcode}
            className={getButtonClass(!isDataFetched || !isValidFadcode, false)}
          >
            Download CSV
          </button>
          <button onClick={handleResetBalance} className={getButtonClass(!isDataFetched || !isValidFadcode, false)}>
            Reset Balance
          </button>
        </div>
      </div>
    </div>
  );
};

export default InputSection;
