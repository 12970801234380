import { Basket } from "./Basket";
import { useTransactionAPI } from "../../lib/transaction-generator/transactionApi";
import { useProcessor } from "../../lib/transaction-generator/contexts/Processor";
import Button from "./Button";
import apiConfig from "./../../config";

export const BasketContainer: React.FC = () => {
  const { createBasket, closeBasket, getLastSeqNumber } = useTransactionAPI(apiConfig.reactAppServerRoot || "");
  const processorCtx = useProcessor();

  const basketOpenClicked = () => {
    async function run() {
      try {
        const { basketId } = await createBasket();
        processorCtx.setBasketId(basketId);
        processorCtx.setBasketOpen(true);
      } catch (e) {
        console.error(e);
      }
    }
    run();
  };

  const basketClosedClicked = () => {
    async function run() {
      try {
        const lastSeq = await getLastSeqNumber();
        const entries = processorCtx.basketItems.length;
        await closeBasket(lastSeq?.data.lastSeqNumber!, entries);
        processorCtx.setBasketOpen(false);
        processorCtx.clearBasket();
      } catch (e) {
        console.error(e);
      }
    }
    run();
  };

  const commitClicked = () => {
    async function run() {
      processorCtx.processBasketItems();
    }

    run();
  };

  return (
    <div className="h-full">
      <div className="flex content-between flex-col h-full">
        <div className="h-full">
          <Basket />
        </div>
        <div className="justify-evenly flex">
          <Button onClick={basketOpenClicked} disabled={processorCtx.basketOpen} className="w-1/3 disabled:bg-gray-500">
            Open Basket
          </Button>
          <Button onClick={commitClicked} disabled={!processorCtx.basketOpen} className="w-1/3">
            Commit Basket
          </Button>
          <Button onClick={basketClosedClicked} disabled={!processorCtx.basketOpen} className="w-1/3">
            Close Basket
          </Button>
        </div>
      </div>
    </div>
  );
};
