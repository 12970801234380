import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Authenticated from "../../components/authenticated";
import { getApiDocumentsClient } from "../../lib/api-helper/apiHelper";
import useApi from "../../lib/hooks/useApi";

export const ApiDocsProject = () => {
  const { project } = useParams<{ project: string }>();
  const [{ data: dataUrl, error }, getDataUrl] = useApi(getApiDocumentsClient, "", project);

  useEffect(() => {
    getDataUrl("openapiApiGet");
  }, [getDataUrl]);

  return (
    <Authenticated>
      {dataUrl ? (
        <iframe
          onLoad={(event) => {
            event.currentTarget.height = `${event.currentTarget.contentWindow.document.body.scrollHeight + 16}px`;
          }}
          title={project}
          srcDoc={dataUrl}
          width="100%"
        />
      ) : null}
      {error ? <p>Unable to get API docs for project</p> : null}
    </Authenticated>
  );
};
