import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { useCommitAndFulfill } from "../../lib/transaction-generator/contexts/CommitAndFulfill";

export const CallbackTabs = () => {
  const commitDetails = useCommitAndFulfill();
  return (
    <Tabs>
      <TabList className={"border-b border-gray-200 dark:border-gray-700"}>
        <Tab
          className={
            "inline-block py-4 px-4 text-sm font-medium text-center text-gray-500 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:red-gray-400 dark:hover:bg-gray-800 dark:hover:text-gray-300 cursor-pointer"
          }
        >
          Commit Success
        </Tab>
        <Tab
          className={
            "inline-block py-4 px-4 text-sm font-medium text-center text-gray-500 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:red-gray-400 dark:hover:bg-gray-800 dark:hover:text-gray-300 cursor-pointer"
          }
        >
          Commit Failure
        </Tab>
        <Tab
          className={
            "inline-block py-4 px-4 text-sm font-medium text-center text-gray-500 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:red-gray-400 dark:hover:bg-gray-800 dark:hover:text-gray-300 cursor-pointer"
          }
        >
          Fulfilment Success
        </Tab>
        <Tab
          className={
            "inline-block py-4 px-4 text-sm font-medium text-center text-gray-500 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:red-gray-400 dark:hover:bg-gray-800 dark:hover:text-gray-300 cursor-pointer"
          }
        >
          Fulfilment Failure
        </Tab>
        <Tab
          className={
            "inline-block py-4 px-4 text-sm font-medium text-center text-gray-500 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:red-gray-400 dark:hover:bg-gray-800 dark:hover:text-gray-300 cursor-pointer"
          }
        >
          Banking fulfilment
        </Tab>
      </TabList>

      <TabPanel>
        <div className="p-2 cursor-pointer break-all">
          <pre>{commitDetails.commitSuccessText || ""}</pre>
        </div>
      </TabPanel>
      <TabPanel>
        <div className="p-2 cursor-pointer break-all">
          <pre>{commitDetails.commitFailureText || ""}</pre>
        </div>
      </TabPanel>
      <TabPanel>
        <div className="p-2 cursor-pointer break-all">
          <pre>{commitDetails.fulfilmentSuccessText || ""}</pre>
        </div>
      </TabPanel>
      <TabPanel>
        <div className="p-2 cursor-pointer break-all">
          <pre>{commitDetails.fulfilmentFailureText || ""}</pre>
        </div>
      </TabPanel>
      <TabPanel>
        <div className="p-2 cursor-pointer break-all">
          <pre>{commitDetails.bankingFulfilmentText || ""}</pre>
        </div>
      </TabPanel>
    </Tabs>
  );
};
