import { useEffect, useRef } from "react";
import { isDataPopulated, initialTcFormData } from "./utils/utils";
import bwipjs from "bwip-js";

interface TableSectionProps {
  fadcodeData: string;
  placeholderText?: string;
}

interface BarcodeSectionProps {
  pouchId: string | null;
  placeholderText?: string;
  errorMessage?: string;
}

interface TcSectionProps {
  tcResult: string | null;
  formData: typeof initialTcFormData;
  placeholderText?: string;
}

interface ResetSectionProps {
  message: string | null;
  placeholderText?: string;
}

export const TableSection = ({ fadcodeData, placeholderText }: TableSectionProps) => {
  // temporary method of validating fadcodes (until API handles it)
  const dataIsPopulated = isDataPopulated(fadcodeData);
  // split the fadcodeData into sections based on the double line breaks
  const sections = fadcodeData.split(/\n\s*\n/).filter(Boolean);
  // parse each section to get the title, headers, and rows
  const parsedSections = sections
    .map((section) => {
      const lines = section.split("\n").filter(Boolean);
      if (!lines || lines.length < 2) {
        return null;
      }
      const headers = lines[1].split(",");
      const rows = lines.slice(2).map((row) => row.split(","));

      //  pick columns to remove
      const columnsToRemove = new Set();
      headers.forEach((_, colIndex) => {
        const isColumnEmpty = rows.every((row) => !row[colIndex] || row[colIndex].trim() === "");
        if (isColumnEmpty) {
          columnsToRemove.add(colIndex);
        }
      });

      // filter out columns
      const filteredHeaders = headers.filter((_, index) => !columnsToRemove.has(index));
      const filteredRows = rows.map((row) => row.filter((_, index) => !columnsToRemove.has(index)));

      return {
        title: lines[0],
        headers: filteredHeaders,
        rows: filteredRows,
      };
    })
    .filter(Boolean);

  return (
    <div className="mb-5 overflow-auto bg-white rounded border" style={{ maxHeight: "800px" }}>
      {fadcodeData ? (
        dataIsPopulated ? (
          parsedSections.map((section, sectionIndex) => (
            <div key={sectionIndex} className="mb-5">
              <h2 className="text-xl font-bold mb-3">{section.title}</h2>
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    {section.headers.map((header, headerIndex) => (
                      <th
                        key={headerIndex}
                        className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
                      >
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {section.rows.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td
                          key={cellIndex}
                          className="px-6 py-4 whitespace-nowrap"
                          data-testid={`${section.title} | ${section.headers[cellIndex]} | ${rowIndex + 1}`}
                        >
                          {cell}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))
        ) : (
          <p className="p-10 text-center text-gray-500">Invalid fadcode.</p>
        )
      ) : (
        <p className="p-10 text-center text-gray-500">{placeholderText}</p>
      )}
    </div>
  );
};

export const BarcodeSection = ({ pouchId, placeholderText, errorMessage }: BarcodeSectionProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (pouchId && canvasRef.current) {
      try {
        bwipjs.toCanvas(canvasRef.current, {
          bcid: "code128",
          text: pouchId,
          scale: 3,
          height: 10,
          includetext: true,
          textxalign: "center",
        });
      } catch (error) {
        console.error("Error rendering barcode:", error);
      }
    }
  }, [pouchId]);

  return (
    <div className="h-32 p-10 mb-5 bg-white rounded border flex items-center justify-center">
      {errorMessage ? (
        <p className="text-center text-red-500">{errorMessage}</p>
      ) : pouchId ? (
        <canvas ref={canvasRef}></canvas>
      ) : (
        <p className="text-center text-gray-500">{placeholderText}</p>
      )}
    </div>
  );
};

export const TcSection = ({ tcResult, formData, placeholderText }: TcSectionProps) => {
  return (
    <div className="mb-5 bg-white rounded border">
      <p className="p-10 text-center text-gray-500">{tcResult ? tcResult : placeholderText}</p>
      {tcResult && (
        <div className="p-5">
          <h3 className="text-lg font-bold mb-3">Form Data:</h3>
          <table className="min-w-full divide-y divide-gray-200">
            <tbody className="bg-white divide-y divide-gray-200">
              {Object.entries(formData).map(([key, value], idx) => (
                <tr key={idx}>
                  <td className="px-6 py-4 whitespace-nowrap font-medium">{key}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{value as string}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

interface ResetSectionProps {
  message: string | null;
}

export const ResetSection = ({ message, placeholderText }: ResetSectionProps) => {
  return (
    <div className="mb-5 bg-white rounded border p-5 text-center">
      {message && (
        <div className={`mt-5 p-4 ${message.includes("successfully") ? "text-green-500" : "text-red-500"}`}>
          {message}
        </div>
      )}
      {!message && <p className="p-10 text-gray-500">{placeholderText}</p>}
    </div>
  );
};
