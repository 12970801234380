import { useEffect, useState } from "react";
import { DeviceApi } from "../../openapi/device-api";
import { getDeviceApiClient } from "../api-helper/apiHelper";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";

const useDeviceAPI = (): DeviceApi => {
  const [deviceClient, setDeviceClient] = useState<DeviceApi>(undefined);
  const [authStatus, setAuthStatus] = useState<string>(undefined);

  onAuthUIStateChange((newAuthState: AuthState) => {
    if (newAuthState === AuthState.SignedIn) {
      setAuthStatus(AuthState.SignedIn);
    }
  });
  useEffect(() => {
    async function run() {
      const client = await getDeviceApiClient();
      setDeviceClient(client);
    }

    run();
  }, [authStatus]);

  return deviceClient;
};

export default useDeviceAPI;
