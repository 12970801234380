import { pause } from "./pause";

export type RetryAsyncOptions = {
  maxAttempts?: number;
  delayMs?: number;
  onError?: (e: any) => void;
  throwOnError?: boolean;
};

export const retryAsync = async (
  asyncFn: () => Promise<any>,
  { maxAttempts = 3, delayMs = 1000, onError = undefined, throwOnError = true }: RetryAsyncOptions = {}
) => {
  if (delayMs < 0) {
    throw new Error("delayMs should be greater than or equal to 0");
  }
  if (maxAttempts <= 0) {
    throw new Error("maxAttempts should be greater than 0");
  }

  let attempts = 0;
  while (attempts < maxAttempts) {
    try {
      return await asyncFn();
    } catch (error) {
      attempts++;
      if (attempts >= maxAttempts) {
        if (onError !== undefined) {
          onError(error);
        }
        if (throwOnError) {
          throw new Error(`Failed after ${maxAttempts} attempts`);
        }
      }
      await pause(delayMs);
      delayMs *= 2;
    }
  }
};
