const Button = (props: React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  const classes = props.className || "";
  return (
    <button
      {...props}
      className={`py-2 px-4 rounded bg-pol-red text-sm text-white hover:bg-pol-red-hover disabled:bg-gray-500 disabled:text-gray-300 ${classes}`}
    >
      {props.children}
    </button>
  );
};

export default Button;
