// Form States
export const initialTcFormData = {
  clientReferenceID: "",
  accountReferenceID: "",
  itemID: "",
  comment: "",
  value: "",
};

// Fadcode Handling
export const isDataPopulated = (jsonData: string): boolean => {
  const sections = jsonData.split(/\n\s*\n/).filter(Boolean);

  if (sections.length === 0) return false;

  for (let section of sections) {
    const lines = section.split("\n").filter(Boolean);
    if (lines.length > 2) {
      const rows = lines.slice(2).map((row) => row.split(","));
      for (let row of rows) {
        for (let cell of row) {
          if (cell.trim() !== "") {
            return true;
          }
        }
      }
    }
  }
  return false;
};

// Date Handling
export const formattedDate = () => {
  const date = new Date();
  const hh = String(date.getHours()).padStart(2, "0");
  const mm = String(date.getMinutes()).padStart(2, "0");
  const ss = String(date.getSeconds()).padStart(2, "0");
  const dd = String(date.getDate()).padStart(2, "0");
  const MM = String(date.getMonth() + 1).padStart(2, "0");
  const yy = String(date.getFullYear()).slice(-2);
  return `${hh}${mm}${ss}${dd}${MM}${yy}`;
};
