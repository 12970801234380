const isJSON = (value: any) => {
  try {
    const parsed = JSON.parse(value);
    return parsed && typeof parsed === "object";
  } catch {
    return false;
  }
};

const getValue = (key: string, partialMatch?: boolean) => {
  if (typeof window === "undefined") {
    console.log("window not set");
    return;
  }

  try {
    if (partialMatch) {
      for (let [localStorageKey, value] of Object.entries(window.localStorage)) {
        if (localStorageKey.includes(key)) {
          return isJSON(value) ? JSON.parse(value) : value;
        }
      }

      return;
    }

    const item = window.localStorage.getItem(key);
    return isJSON(item) ? JSON.parse(item) : item;
  } catch (error) {
    console.log(error);
  }
};

const setValue = (key: string, value: string) => {
  if (typeof window === "undefined") {
    console.log("window not set");
    return;
  }

  window.localStorage.setItem(key, value);
};

export { getValue, setValue };
