import { Link } from "react-router-dom";
import "./table.css";

interface Cell {
  value: unknown;
  linkTo?: string;
}

type Row = Cell[];

export interface TableProps {
  cols: string[];
  rows: Row[];
  className?: string;
}

export const Table = ({ cols, rows, className }: TableProps) => {
  return (
    <div className={`flex shadow-md bg-gray-200 rounded-lg overflow-auto ${className || ""}`}>
      <table className="flex-1">
        <thead>
          <tr>
            {cols.map((col) => (
              <th key={col} className="text-lg font-medium">
                {col}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => (
            <tr key={JSON.stringify(row)}>
              {row.map((cell) => (
                <td key={`${cell.value}`} className="text-lg font-medium">
                  {cell.linkTo ? <Link to={cell.linkTo}>{`${cell.value}`}</Link> : <p>{`${cell.value}`}</p>}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
