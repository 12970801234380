import { useGlobalState } from "../../lib/GlobalState";
import { useDeviceAttributes } from "../../lib/transaction-generator/contexts/DeviceAttributes";
import { useProcessor } from "../../lib/transaction-generator/contexts/Processor";
import Select from "react-select";

export const FADNodeID = () => {
  const attrs = useDeviceAttributes();
  const { basketOpen } = useProcessor();
  const { devices, selectedDevice, setSelectedDevice } = useGlobalState();

  const AttrInfo = () => {
    return (
      <>
        <span className="block mr-2">FAD code: {attrs.branchID}</span>
        <span className="block mr-2">Node ID: {attrs.nodeID}</span>
        <span className="block">Basket: {basketOpen ? "open" : "closed"}</span>
      </>
    );
  };

  if (devices?.length === 0 || !selectedDevice) {
    return (
      <div className="flex content-center items-center">
        <span className="mr-2">No devices or device tokens available</span>
        <AttrInfo />
      </div>
    );
  }

  return (
    <div className="flex content-center items-center">
      <span className="block mr-2">
        <label htmlFor="deviceSelect">Device: </label>
      </span>
      <Select
        onChange={({ value: newDevice }) => setSelectedDevice(newDevice)}
        placeholder={"Select Device"}
        name="deviceSelect"
        defaultInputValue={selectedDevice.id}
        value={{ value: selectedDevice, label: selectedDevice.id }}
        defaultValue={{ value: selectedDevice, label: selectedDevice.id }}
        options={devices?.map((d) => {
          return { value: d, label: d.id };
        })}
        className="inline-block mr-4"
      />
      <AttrInfo />
    </div>
  );
};
