import { Context, createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from "react";
import { DeviceSimulator } from "../openapi/device-management-api/api";
import useDeviceAPI from "./hooks/useDeviceApi";
import { DeviceConfig } from "./models";
import useDeviceManagementAPI from "./hooks/useDeviceManagementApi";
import { useAuth, Tokens } from "./hooks/useAuth";
import { useFetchDevices } from "./hooks/useFetchDevices/useFetchDevices";

type GlobalState = {
  deviceConfig: DeviceConfig;
  // TODO: [SimulatorRefactor] alias this type to "device" rather than deviceSimulator
  devices: DeviceSimulator[];
  tokens: Tokens;
  // TODO: [SimulatorRefactor] remove
  setDeviceConfig: Dispatch<SetStateAction<DeviceConfig>>;
  // TODO: [SimulatorRefactor] SelectedDevice should contain all of the related data (tokens and deviceConfig.
  selectedDevice: DeviceSimulator;
  // TODO: [SimulatorRefactor] remove .. also alias type to device.
  setSelectedDevice: Dispatch<SetStateAction<DeviceSimulator>>;
  // TODO: [SimulatorRefactor] remove sandbox; prefer "embedded application config"
  sandboxParams: string;
  setSandboxParams: Dispatch<SetStateAction<string>>;
  // FIXME: [SimulatorRefactor] Move this outside of here.
  getDevices: () => void;
  isLoading: boolean;
};

// FIXME: [SimulatorRefactor] Split context: data from dispatch
const GlobalStateContext: Context<GlobalState> = createContext<GlobalState>({
  deviceConfig: {
    deviceId: null,
    terminalId: null,
  },
  devices: null,
  tokens: {},
  setDeviceConfig: () => {},
  selectedDevice: undefined,
  setSelectedDevice: () => {},
  setSandboxParams: () => {},
  sandboxParams: null,
  getDevices: () => {},
  isLoading: true,
});

// FIXME: [SimulatorRefactor] redundant? (possibly for tests?)
export const useGlobalState = () => {
  const ctx = useContext(GlobalStateContext);

  if (!ctx) {
    throw new Error("useGlobalState must be used within a GlobalStateProvider component");
  }

  return ctx;
};

export function GlobalStateProvider({ children }: { children: ReactNode }): JSX.Element {
  const [deviceConfig, setDeviceConfig] = useState<DeviceConfig>({
    deviceId: null,
    terminalId: null,
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [sandboxParams, setSandboxParams] = useState<string>(null);
  const { devices, getDevices } = useFetchDevices();
  const { tokens, getTokens } = useAuth();
  const [selectedDevice, setSelectedDevice] = useState<DeviceSimulator>(undefined);
  // FIXME: [SimulatorRefactor] DeviceAPI rename -> SimulatorAPI
  const deviceApi = useDeviceAPI();
  const deviceManagementApi = useDeviceManagementAPI();

  // FIXME: [SimulatorCleanup] Fetch when ready on devices onLoad and whenever devices change only.
  useEffect(() => {
    async function run() {
      await getDevices();
    }

    run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceManagementApi]);

  useEffect(() => {
    async function run() {
      if (selectedDevice) {
        await getTokens(deviceApi, selectedDevice);
      }
    }

    run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDevice]);

  // TODO: [SimulatorCleanup] invalidate previous device immediately
  useEffect(() => {
    if (devices) {
      setIsLoading(false);
      if (!selectedDevice && devices.length > 0) {
        setSelectedDevice(devices[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devices, tokens]);

  return (
    <GlobalStateContext.Provider
      value={{
        deviceConfig,
        setDeviceConfig,
        devices,
        tokens,
        selectedDevice,
        setSelectedDevice,
        setSandboxParams,
        sandboxParams,
        getDevices,
        isLoading,
      }}
    >
      {children}
    </GlobalStateContext.Provider>
  );
}
