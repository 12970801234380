import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import Authenticated from "../../components/authenticated";
import { Table } from "../../components/layout/table";
import { getApiDocumentsClient } from "../../lib/api-helper/apiHelper";
import useApi from "../../lib/hooks/useApi";
import { TestSpecs } from "../../openapi/api-documents/api";

export const ApiDocsUnitTests = () => {
  const { project } = useParams<{ project: string }>();
  const [{ data, error }, getUnitTests] = useApi(getApiDocumentsClient, "", project);
  const unitTests = useMemo(() => (data as TestSpecs | undefined)?.specs.sort((a, b) => (a < b ? -1 : 1)), [data]);

  useEffect(() => {
    getUnitTests("testResultsApiGet");
  }, [getUnitTests]);

  const tableData = useMemo(
    () =>
      unitTests
        ? {
            cols: ["Unit Test"],
            rows: unitTests.map((unitTest) => [
              {
                value: unitTest,
                linkTo: `/api-docs/project/${project}/unitTests/${unitTest}`,
              },
            ]),
          }
        : null,
    [project, unitTests]
  );

  return (
    <Authenticated>
      <div className="flex flex-col max-w-3xl px-4 py-12 mx-auto sm:px-6 lg:py-16 lg:px-8">
        <h1 className="space-y-8 text-3xl font-extrabold text-gray-900 sm:text-4xl">{`${project} - Unit Tests`}</h1>
        <p className="mt-3 mb-6 text-lg text-gray-500">
          This page provides a comprehensive list of unit tests for this API, allowing developers to quickly and easily
          check the functionality of specific endpoints. Click on a test case to see the sequence diagram, along with
          the payload of each step.
        </p>
        {tableData ? <Table className="flex-1" cols={tableData.cols} rows={tableData.rows} /> : null}
        {error ? <p>Unable to get unit tests for project</p> : null}
      </div>
    </Authenticated>
  );
};
