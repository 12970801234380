import { useEffect } from "react";
import { useGlobalState } from "../lib/GlobalState";
import Authenticated from "../components/authenticated";
import { FADNodeID } from "../components/transactionGenerator/FADNodeID";
import { BasketContainer } from "../components/transactionGenerator/BasketContainer";
import { CallbackTabs } from "../components/transactionGenerator/CallbackTabs";
import EntryMaker from "../components/transactionGenerator/EntryMaker";
import { ReactNode } from "react";
import { CommitAndFulfillProvider } from "../lib/transaction-generator/contexts/CommitAndFulfill";
import { DeviceAttributesProvider } from "../lib/transaction-generator/contexts/DeviceAttributes";
import { ProcessorProvider } from "../lib/transaction-generator/contexts/Processor";

const UIBlock = ({ children }: { children: ReactNode; style?: string }) => {
  return (
    <div className="shadow-md bg-gray-200 text-gray-800 text-lg p-2 rounded-lg row-span-2 col-span-2 font-bold">
      {children}
    </div>
  );
};

const TransactionGenerator = (): JSX.Element => {
  const { getDevices } = useGlobalState();

  useEffect(() => {
    getDevices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Authenticated>
      <CommitAndFulfillProvider>
        <DeviceAttributesProvider>
          <ProcessorProvider>
            <div>
              <div className="grid grid-cols-3 gap-4 p-5 h-full">
                <div className="text-gray-800 font-bold p-2 rounded-lg row-span-1 col-span-3">
                  <FADNodeID />
                </div>

                <UIBlock>
                  <CallbackTabs />
                </UIBlock>

                <div className="shadow-md bg-gray-200 text-gray-800 text-lg font-bold text-center p-2 rounded-lg row-span-4 col-span-1">
                  <BasketContainer />
                </div>

                <UIBlock>
                  <EntryMaker />
                </UIBlock>
              </div>
            </div>
          </ProcessorProvider>
        </DeviceAttributesProvider>
      </CommitAndFulfillProvider>
    </Authenticated>
  );
};

export default TransactionGenerator;
