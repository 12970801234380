import React, { createContext, Dispatch, SetStateAction, useContext, useState } from "react";

interface CommitAndFulfillContext {
  commitSuccessText: string;
  commitFailureText: string;
  fulfilmentSuccessText: string;
  fulfilmentFailureText: string;
  bankingFulfilmentText: string;
  setCommitSuccessText: Dispatch<SetStateAction<string>>;
  setCommitFailureText: Dispatch<SetStateAction<string>>;
  setFulfilmentSuccessText: Dispatch<SetStateAction<string>>;
  setFulfilmentFailureText: Dispatch<SetStateAction<string>>;
  setBankingFulfilmentText: Dispatch<SetStateAction<string>>;
}

const FulfilContext = createContext<CommitAndFulfillContext>({
  commitFailureText: "",
  commitSuccessText: "",
  fulfilmentFailureText: "",
  fulfilmentSuccessText: "",
  bankingFulfilmentText: "",
  setCommitFailureText: () => {},
  setCommitSuccessText: () => {},
  setFulfilmentFailureText: () => {},
  setFulfilmentSuccessText: () => {},
  setBankingFulfilmentText: () => {},
});

function CommitAndFulfillProvider({ children }: { children: JSX.Element }): JSX.Element {
  const [commitSuccessText, setCommitSuccessText] = useState("");
  const [commitFailureText, setCommitFailureText] = useState("");
  const [fulfillmentFailureText, setFulfilmentFailureText] = useState("");
  const [fulfillmentSuccessText, setFulfilmentSuccessText] = useState("");
  const [bankingFulfilmentText, setBankingFulfilmentText] = useState("");

  return (
    <FulfilContext.Provider
      value={React.useMemo(
        () => ({
          commitFailureText,
          commitSuccessText,
          fulfilmentFailureText: fulfillmentFailureText,
          fulfilmentSuccessText: fulfillmentSuccessText,
          bankingFulfilmentText,
          setCommitFailureText,
          setCommitSuccessText,
          setFulfilmentFailureText,
          setFulfilmentSuccessText,
          setBankingFulfilmentText,
        }),
        [commitFailureText, commitSuccessText, fulfillmentFailureText, fulfillmentSuccessText, bankingFulfilmentText]
      )}
    >
      {children}
    </FulfilContext.Provider>
  );
}

function useCommitAndFulfill() {
  const context = useContext(FulfilContext);

  if (!context) {
    throw new Error("useCommitAndFulfill must be used within a CommitAndFulfillProvider");
  }
  return context;
}

export { CommitAndFulfillProvider, useCommitAndFulfill };
