import { useState } from "react";
import InputSection from "./InputSection";
import { initialTcFormData } from "./utils/utils";
import { TableSection, BarcodeSection, TcSection, ResetSection } from "./OutputSection";
import Authenticated from "../../components/authenticated";

const BoParent = () => {
  const [fadCode, setFadCode] = useState<string>("");
  const [isValidFadcode, setIsValidFadcode] = useState<boolean | null>(null);
  const [fadcodeData, setFadcodeData] = useState<string>("");
  const [dataIsFetched, setDataIsFetched] = useState<boolean>(false);
  const [numOfNotes, setNumOfNotes] = useState<string>("0");
  const [pouchBarcode, setPouchBarcode] = useState<string | null>(null);
  const [despatchBarcode, setDespatchBarcode] = useState<string | null>(null);
  const [tcResult, setTcResult] = useState<string | null>(null);
  const [lastSubmittedData, setLastSubmittedData] = useState(initialTcFormData);
  const [resetBalanceResult, setResetBalanceResult] = useState<string | null>(null);

  // TODO: expand to include other api call error messages when api updated to handle them (invalid fadcode etc)
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  return (
    <Authenticated>
      <div className="flex p-10 space-x-10">
        <div className="w-1/3 p-2">
          <InputSection
            fadCode={fadCode}
            setFadCode={setFadCode}
            isValidFadcode={isValidFadcode}
            setIsValidFadcode={setIsValidFadcode}
            isDataFetched={dataIsFetched}
            numOfNotes={numOfNotes}
            setNumOfNotes={setNumOfNotes}
            setFadcodeData={setFadcodeData}
            setResetBalanceResult={setResetBalanceResult}
            fadcodeData={fadcodeData}
            setDataIsFetched={setDataIsFetched}
            setPouchBarcode={setPouchBarcode}
            setDespatchBarcode={setDespatchBarcode}
            setErrorMessage={setErrorMessage}
            setTcResult={setTcResult}
            initialTcFormData={initialTcFormData}
            lastSubmittedData={lastSubmittedData}
            setLastSubmittedData={setLastSubmittedData}
          />
        </div>
        <div className="w-2/3 p-2 border rounded shadow-lg">
          <h3 className="text-m font-semibold bg-gray-100 py-2 px-4 rounded-md mb-4">Fadcode Data</h3>
          <TableSection fadcodeData={fadcodeData} placeholderText="Fadcode Data will appear here" />

          <h3 className="text-m font-semibold bg-gray-100 py-2 px-4 rounded-md mb-4 mt-6">Pouch Barcode</h3>
          <BarcodeSection pouchId={pouchBarcode} placeholderText="Pouch Barcode will appear here" />

          <h3 className="text-m font-semibold bg-gray-100 py-2 px-4 rounded-md mb-4 mt-6">Pouch Despatch Barcode</h3>
          <BarcodeSection
            pouchId={despatchBarcode}
            placeholderText="Pouch Despatch Barcode will appear here"
            errorMessage={errorMessage}
          />

          <h3 className="text-m font-semibold bg-gray-100 py-2 px-4 rounded-md mb-4 mt-6">TC Generation</h3>
          <TcSection tcResult={tcResult} formData={lastSubmittedData} placeholderText="TC Data will appear here" />

          <h3 className="text-m font-semibold bg-gray-100 py-2 px-4 rounded-md mb-4 mt-6">Reset Balance</h3>
          <ResetSection message={resetBalanceResult} placeholderText="Press 'Reset Balance' to reset the balance" />
        </div>
      </div>
    </Authenticated>
  );
};

export default BoParent;
