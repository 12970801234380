import { useEffect, useMemo } from "react";
import Authenticated from "../../components/authenticated";
import { Table, TableProps } from "../../components/layout/table/table";
import { getApiDocumentsClient } from "../../lib/api-helper/apiHelper";
import useApi from "../../lib/hooks/useApi";
import { ApiSpecs } from "../../openapi/api-documents/api";

export const ApiDocsProjects = () => {
  const [{ data, error }, getProjects] = useApi(getApiDocumentsClient);
  const projects = useMemo(() => (data as ApiSpecs | undefined)?.specs.sort((a, b) => (a < b ? -1 : 1)), [data]);

  useEffect(() => {
    getProjects("rootGet");
  }, [getProjects]);

  const tableData: TableProps | null = useMemo(
    () =>
      projects
        ? {
            cols: ["API", "Unit Test"],
            rows: projects.map((project) => [
              {
                value: project,
                linkTo: `/api-docs/project/${project}`,
              },
              {
                value: `${project} Test Results`,
                linkTo: `/api-docs/project/${project}/unitTests`,
              },
            ]),
          }
        : null,
    [projects]
  );

  return (
    <Authenticated>
      <div className="flex flex-col max-w-3xl px-4 py-12 mx-auto sm:px-6 lg:py-16 lg:px-8">
        <h1 className="space-y-8 text-3xl font-extrabold text-gray-900 sm:text-4xl">API Documentation</h1>
        <p className="mt-3 mb-3r text-lg text-gray-500">
          This resource contains instructions and details for making API requests, including accepted parameters and
          expected responses. Essential for developers integrating APIs and troubleshooting issues. Refer to it for
          guidance and contact API teams for feedback or suggestions.
        </p>
        <p className="mt-3 mb-6 text-lg text-gray-500">
          The links in the "API" column will take you to the documentation for that API, whereas the links in the "Unit
          Test" column will allow you to examine the steps taken by its unit tests.
        </p>
        {tableData ? <Table className="flex-1" cols={tableData.cols} rows={tableData.rows} /> : null}
        {error ? <p>Unable to get list of APIs</p> : null}
      </div>
    </Authenticated>
  );
};
