import CodeEditor from "@uiw/react-textarea-code-editor";
import htmr from "htmr";
import { compile as compileReceipt } from "postoffice-receipt-generator";
import { FC, useCallback, useEffect, useState } from "react";
import Authenticated from "../components/authenticated";
import { Constants } from "../constants";

const ReceiptViewer: FC = () => {
  const [template, setTemplate] = useState(`{{ format:alignCenter "Post Office Ltd."}}
{{ format:alignCenter "Your Receipt"}}

{{ format:twoColumn (concat date " " time) (concat "TP: " device.nodeID) }}`);

  const [context, setContext] = useState(`{
  "textMode": true,
  "date": "2/2/2022",
  "time": "22:22",
  "device": {
    "nodeID": "56"
  }
}`);

  const [html, setHtml] = useState("");

  const fontFamily = "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace";
  const codeBlockFontSize = 14;

  const compile = useCallback(async () => {
    if (!template) {
      alert("Please enter template");
      return;
    }

    if (!context) {
      alert("Please enter context variables");
      return;
    }

    try {
      const contextVars = JSON.parse(context);
      const compiledTemplate = await compileReceipt(template, contextVars);
      setHtml(contextVars.textMode ? `<pre>${compiledTemplate}</pre>` : compiledTemplate);
    } catch (_) {}
  }, [context, template]);

  useEffect(() => {
    compile();
  }, [compile]);

  return (
    <Authenticated>
      <div className="flex flex-col absolute inset-0 top-header">
        <button onClick={compile} className="w-full py-2 font-bold text-center text-white bg-green-600">
          COMPILE
        </button>
        <div className="flex-1 min-h-0">
          <div className="grid grid-rows-receipt-screen grid-cols-receipt-screen mx-auto max-w-screen-3xl h-full p-4 gap-x-8 gap-y-4">
            <h1 className="text-2xl font-bold">{Constants.RECEIPT_TEMPLATE}</h1>
            <h1 className="text-2xl font-bold">{Constants.RECEIPT_CONTEXT}</h1>
            <h1 className="text-2xl font-bold">{Constants.RECEIPT_PREVIEW}</h1>
            <CodeEditor
              value={template}
              language="handlebars"
              placeholder={Constants.ENTER_HANDLEBARS_TEMPLATE}
              onChange={(evn) => setTemplate(evn.target.value)}
              padding={15}
              style={{
                fontSize: codeBlockFontSize,
                backgroundColor: "#ffffff",
                overflow: "scroll",
                fontFamily,
              }}
            />
            <CodeEditor
              value={context}
              language="json"
              placeholder={Constants.ENTER_CONTEXT}
              onChange={(evn) => setContext(evn.target.value)}
              padding={15}
              style={{
                fontSize: codeBlockFontSize,
                backgroundColor: "#ffffff",
                overflow: "scroll",
                fontFamily,
              }}
            />
            {html && <div className="self-start p-8 bg-white shadow-2xl overflow-scroll max-h-full">{htmr(html)}</div>}
          </div>
        </div>
      </div>
    </Authenticated>
  );
};

export default ReceiptViewer;
