import { useProcessor } from "../../lib/transaction-generator/contexts/Processor";
import BasketEntry from "./BasketEntry";

export const Basket = () => {
  const processorCtx = useProcessor();

  return (
    <div className="h-full">
      {processorCtx.basketItems.map((item) => {
        return <BasketEntry key={Math.random() + Date.now().toString()} item={item} />;
      })}
    </div>
  );
};
