import { useEffect, useState } from "react";
import { SimulatorDeviceManagementApi } from "../../openapi/device-management-api";
import { getDeviceManagementApiClient } from "../api-helper/apiHelper";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";

const useDeviceManagementAPI = (): SimulatorDeviceManagementApi => {
  const [deviceClient, setDeviceClient] = useState<SimulatorDeviceManagementApi>(undefined);
  const [authStatus, setAuthStatus] = useState<string>(undefined);

  //TODO add unit test
  onAuthUIStateChange((newAuthState: AuthState) => {
    if (newAuthState === AuthState.SignedIn) {
      setAuthStatus(AuthState.SignedIn);
    }
  });
  useEffect(() => {
    async function run() {
      const client = await getDeviceManagementApiClient();
      setDeviceClient(client);
    }
    run();
  }, [authStatus]);

  return deviceClient;
};

export default useDeviceManagementAPI;
