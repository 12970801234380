import React from "react";
import ReactDOM from "react-dom";
import "./assets/css/tailwind.css";
import "./assets/css/app.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Auth } from "aws-amplify";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import apiConfig from "./config";

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: apiConfig.launchDarklyKey,
    options: {
      application: {
        id: "simulator-application",
      },
    },
  });
  Auth.configure(apiConfig.awsSimulatorConfig);

  ReactDOM.render(
    <React.StrictMode>
      <LDProvider>
        <App />
      </LDProvider>
    </React.StrictMode>,
    document.getElementById("root")
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
